//google places
jQuery(document).ready(function ($) {
    const element = document.getElementById("autocomplete");

    if (element) {
        importMap();
    }
    var autocomplete;

    function importMap() {
        ((g) => {
            var h,
                a,
                k,
                p = " The Google Maps JavaScript API",
                c = "google",
                l = "importLibrary",
                q = "__ib__",
                m = document,
                b = window;
            b = b[c] || (b[c] = {});
            var d = b.maps || (b.maps = {}),
                r = new Set(),
                e = new URLSearchParams(),
                u = () =>
                    h ||
                    (h = new Promise(async (f, n) => {
                        await (a = m.createElement("script"));
                        e.set("libraries", [...r] + "");
                        for (k in g)
                            e.set(
                                k.replace(
                                    /[A-Z]/g,
                                    (t) => "_" + t[0].toLowerCase()
                                ),
                                g[k]
                            );
                        e.set("callback", c + ".maps." + q);
                        a.src =
                            `https://maps.${c}apis.com/maps/api/js?libraries=places&` +
                            e;
                        d[q] = f;
                        a.onerror = () =>
                            (h = n(Error(p + " could not load.")));
                        a.nonce = m.querySelector("script[nonce]")?.nonce || "";
                        m.head.append(a);
                    }));
            d[l]
                ? console.warn(p + " only loads once. Ignoring:", g)
                : (d[l] = (f, ...n) =>
                      r.add(f) && u().then(() => d[l](f, ...n)));
        })({
            key: site_data.google_api_key,
            v: "weekly",
            // Use the 'v' parameter to indicate the version to use (weekly, beta, alpha, etc.).
            // Add other bootstrap parameters as needed, using camel case.
        });
    }

    if (element) {
        google.maps.importLibrary("places").then(function (Place) {
            if ($("#autocomplete").length) {
                initAutocomplete();
            }
        });
        function initAutocomplete() {
            autocomplete = new google.maps.places.Autocomplete(
                document.getElementById("autocomplete"),
                { types: ["geocode"] }
            );
            autocomplete.setFields(["address_component", "geometry"]);
            autocomplete.addListener("place_changed", fillInAddress);
        }

        function fillInAddress() {
            var place = autocomplete.getPlace();

            var town = "",
                postcode = "",
                county = "",
                stateorprovince = "",
                country = "";

            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];

                if (
                    addressType == "postal_town" ||
                    addressType == "locality" || // an incorporated city or town political entity.
                    addressType == "sublocality_level_1" || // a first-order civil entity below a locality (Brooklyn)
                    addressType == "administrative_area_level_3" // a third-order civil entity below the country level
                ) {
                    if (
                        place.address_components[i]["long_name"] !== undefined
                    ) {
                        town = place.address_components[i]["long_name"];
                    }
                }

                if (addressType == "administrative_area_level_2") {
                    //Within the United States, these administrative levels are counties.
                    if (
                        place.address_components[i]["long_name"] !== undefined
                    ) {
                        county = place.address_components[i]["long_name"];
                    }
                }

                if (addressType == "administrative_area_level_1") {
                    //Within the United States, these administrative levels are counties.
                    if (
                        place.address_components[i]["long_name"] !== undefined
                    ) {
                        stateorprovince =
                            place.address_components[i]["long_name"];
                    }
                }

                if (addressType == "country") {
                    if (
                        place.address_components[i]["long_name"] !== undefined
                    ) {
                        country = place.address_components[i]["long_name"];
                    }
                }

                if (addressType == "postal_code") {
                    if (
                        place.address_components[i]["short_name"] !== undefined
                    ) {
                        postcode = place.address_components[i]["short_name"];
                    }
                }
            }

            var lat = place.geometry.location.lat().toString();
            var lng = place.geometry.location.lng().toString();

            document.getElementById("user_town").value = town;
            document.getElementById("user_county").value = county;
            document.getElementById("user_stateorprovince").value =
                stateorprovince;
            document.getElementById("user_country").value = country;
            document.getElementById("user_postcode").value = postcode;
            document.getElementById("user_latitude").value = lat;
            document.getElementById("user_longitude").value = lng;
        }
    }
});
